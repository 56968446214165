import './styles.css';

import _ from 'lodash';
import Packery from 'packery';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

const MIN_BUBBLE_SIZE = 72;
const MAX_BUBBLE_SIZE = 192;
const MIN_FONT_SIZE = 12;
const MAX_FONT_SIZE = 36;

const keyFn = (key) => _(key).lowerCase().replace(/\s+/g, '-');

class CircleTagPicker extends Component {
  constructor(props) {
    super(props);
    this.circleRefs = {};
  }

  componentDidMount() {
    const { selectedTags } = this.props;
    const container = document.querySelector('.container');
    this.packery = new Packery(container, {
      itemSelector: '.item',
      gutter: 4,
      stamp: '.stamp',
    });
    _.forEach(_.difference(selectedTags), (tag) =>
      this.updateCircle(tag, true)
    );
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { selectedTags } = this.props;
    if (!_.eq(selectedTags, nextProps.selectedTags)) {
      _.forEach(_.difference(selectedTags, nextProps.selectedTags), (tag) =>
        this.updateCircle(tag, false)
      );
      _.forEach(_.difference(nextProps.selectedTags, selectedTags), (tag) =>
        this.updateCircle(tag, true)
      );
    }
  }

  shouldComponentUpdate() {
    return false;
  }

  updateCircle(datum, selected) {
    const circleRef = this.circleRefs[keyFn(datum.tag)];
    circleRef.setAttribute('selected', selected);
  }

  selectCircle(datum) {
    const { onTagSelected, onTagUnselected } = this.props;
    const circleRef = this.circleRefs[keyFn(datum.tag)];
    const selected = circleRef.getAttribute('selected') === 'true';
    if (selected) {
      onTagUnselected(datum);
    } else {
      onTagSelected(datum);
    }
  }

  renderCircle(datum) {
    const { circles } = this.props;
    const { weight } = datum;
    const maxWeight = _.maxBy(circles, 'weight').weight;
    return (
      <button
        key={keyFn(datum.tag)}
        ref={(ref) => {
          this.circleRefs[keyFn(datum.tag)] = ref;
        }}
        className="item circle"
        onClick={() => this.selectCircle(datum)}
        style={{
          height:
            MIN_BUBBLE_SIZE +
            (MAX_BUBBLE_SIZE - MIN_BUBBLE_SIZE) * (weight / maxWeight),
          width:
            MIN_BUBBLE_SIZE +
            (MAX_BUBBLE_SIZE - MIN_BUBBLE_SIZE) * (weight / maxWeight),
        }}
        type="button"
      >
        <span
          className="tag-text"
          style={{
            fontSize:
              MIN_FONT_SIZE +
              (MAX_FONT_SIZE - MIN_FONT_SIZE) * (weight / maxWeight),
          }}
        >
          {datum.tag}
        </span>
      </button>
    );
  }

  render() {
    const { circles } = this.props;
    return (
      <div className="container" style={{ width: '96%' }}>
        {_.map(_.shuffle(circles), (c) => this.renderCircle(c))}
      </div>
    );
  }
}

CircleTagPicker.propTypes = {
  selectedTags: PropTypes.arrayOf(PropTypes.object).isRequired,
  onTagSelected: PropTypes.func.isRequired,
  onTagUnselected: PropTypes.func.isRequired,
  circles: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default CircleTagPicker;
