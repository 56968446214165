export const AnimatedClasses = {
  bottomNavFavoriteIcon: 'bottom-nav-favorite-icon',
};

export const animate = (className) => {
  document.querySelector(`.${className}`).classList.add('animated');
  setTimeout(() => {
    document.querySelector(`.${className}`).classList.remove('animated');
  }, 1000);
};
