import Axios from 'axios';
import firebase from 'firebase';
import _ from 'lodash';
import { getCurrentUserUid } from 'src/helpers/user';

const db = firebase.firestore();

const FETCH_FEED = 'products/FETCH_FEED';
const SET_FEED = 'products/SET_FEED';
const HIDE_PRODUCT = 'products/HIDE_PRODUCT';
const ADD_FAVORITE = 'products/ADD_FAVORITE';
const REMOVE_FAVORITE = 'products/REMOVE_FAVORITE';

const initialState = {
  products: [],
};

export const productsSelector = (state) => state.products.products;

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_FEED:
      return {
        ...state,
        products: payload,
      };
    case HIDE_PRODUCT:
      return {
        ...state,
        products: _.reject(state.products, payload),
      };
    default:
      return state;
  }
};

export const setProducts = (products) => (dispatch) => {
  dispatch({ type: SET_FEED, payload: products });
};

export const getFeed = (styleProfile) => async (dispatch) => {
  dispatch({ type: FETCH_FEED, payload: styleProfile });
  return Axios.get(process.env.REACT_APP_CURATION_API_URL, {
    params: {
      term1: styleProfile[0].tag,
      term2: styleProfile[1].tag,
      term3: styleProfile[2].tag,
    },
  }).then(({ data: { data } }) => data);
};

export const hideProduct = (product) => (dispatch) => {
  dispatch({ type: HIDE_PRODUCT, payload: product });
  db.collection('users')
    .doc(getCurrentUserUid())
    .set(
      {
        dislikes: {
          [product.asin]: Date.now(),
        },
      },
      { merge: true }
    );
};

export const addFavorite = (product) => (dispatch) => {
  dispatch({ type: ADD_FAVORITE, payload: product });
  db.collection('users')
    .doc(getCurrentUserUid())
    .set(
      {
        favorites: {
          [product.asin]: product,
        },
      },
      { merge: true }
    );
};

export const removeFavorite = (product) => (dispatch) => {
  dispatch({ type: REMOVE_FAVORITE, payload: product });
  db.collection('users')
    .doc(getCurrentUserUid())
    .set(
      {
        favorites: {
          [product.asin]: firebase.firestore.FieldValue.delete(),
        },
      },
      { merge: true }
    );
};
