const SHOW_ALERT = 'alert/SHOW_ALERT';
const HIDE_ALERT = 'alert/HIDE_ALERT';

/**
 * @typedef {object} Alert
 * @property {boolean} visible
 * @property {string} message
 * @property {'success'|'error'|'info'} severity
 */

/** @type {Alert} */
const initialState = {
  visible: false,
  message: '',
  severity: 'success',
};

/**
 * Selects current alert
 * @param {object} state
 * @return {Alert}
 */
export const alertSelector = (state) => state.alert;

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SHOW_ALERT:
      return payload;
    case HIDE_ALERT:
      return {
        ...state,
        visible: false,
      };
    default:
      return state;
  }
};

/**
 * Change current alert
 * @param {Alert} alert
 */
export const showAlert = (alert) => (dispatch) =>
  dispatch({
    type: SHOW_ALERT,
    payload: {
      ...alert,
      visible: true,
    },
  });

/**
 * Hide alert
 */
export const hideAlert = () => (dispatch) =>
  dispatch({
    type: HIDE_ALERT,
  });
