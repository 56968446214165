import { Box, useTheme } from '@material-ui/core';
import useWindowSize from '@rooks/use-window-size';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useRef } from 'react';
import { useBottomScrollListener } from 'react-bottom-scroll-listener';
import { trackWindowScroll } from 'react-lazy-load-image-component';
import { useSelector } from 'react-redux';
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  Masonry,
  createMasonryCellPositioner,
} from 'react-virtualized';
import ProductCard from 'src/components/ProductCard';
import { CARD_WIDTH } from 'src/constants';
import { columnPreferenceSelector, userSelector } from 'src/store/user';

const GUTTER_WIDTH = 10;
const HORIZONTAL_PADDING = 8;

const ProductList = ({
  products,
  onBottomReached,
  emptyPlaceholder,
  isFavoriteList,
  checkFavoriteFn,
  scrollPosition,
}) => {
  const { innerWidth } = useWindowSize();
  const { dislikes } = useSelector(userSelector);
  const currentColumnCount = useSelector(columnPreferenceSelector);
  const columnWidth =
    currentColumnCount === 1
      ? CARD_WIDTH
      : Math.min(
          window.innerWidth / currentColumnCount - 2 * HORIZONTAL_PADDING,
          400
        );

  const currentMasonryWidth =
    columnWidth * currentColumnCount + (currentColumnCount - 1) * GUTTER_WIDTH;
  const theme = useTheme();

  const masonryRef = useRef();

  const cache = useRef(
    new CellMeasurerCache({
      defaultWidth: columnWidth,
      fixedWidth: true,
      // fixedHeight: false,
    })
  );

  const cellPositioner = useRef(
    createMasonryCellPositioner({
      cellMeasurerCache: cache.current,
      columnCount: currentColumnCount,
      columnWidth,
      spacer: GUTTER_WIDTH,
    })
  ).current;

  const displayProducts = useMemo(
    () => _.reject(products, (p) => !!dislikes[p.asin]),
    [products, dislikes]
  );

  const cellRenderer = ({
    // eslint-disable-next-line react/prop-types
    index,
    key,
    parent,
    style,
  }) => {
    const product = displayProducts[index];
    if (!product) {
      return null;
    }
    return (
      <CellMeasurer
        key={key}
        cache={cache.current}
        index={index}
        parent={parent}
      >
        <ProductCard
          key={product.asin}
          isFavorite={checkFavoriteFn(product)}
          product={product}
          scrollPosition={scrollPosition}
          showAlways={!isFavoriteList}
          style={style}
          width={columnWidth}
        />
      </CellMeasurer>
    );
  };

  useBottomScrollListener(onBottomReached || _.noop);

  useEffect(() => {
    if (masonryRef.current) {
      cellPositioner.reset({
        columnCount: currentColumnCount,
        columnWidth,
        spacer: GUTTER_WIDTH,
      });
      masonryRef.current.recomputeCellPositions();
    }
  }, [currentColumnCount, innerWidth]);

  if (_.isEmpty(products)) {
    return emptyPlaceholder;
  }

  return (
    <Box display="flex" flex={1}>
      <AutoSizer>
        {({ width, height }) => (
          <Masonry
            ref={masonryRef}
            cellCount={displayProducts.length}
            cellMeasurerCache={cache.current}
            cellPositioner={cellPositioner}
            cellRenderer={cellRenderer}
            height={height}
            style={{
              outline: 'none',
              paddingTop: theme.spacing(4),
              paddingBottom: theme.spacing(4),
              paddingLeft: (width - currentMasonryWidth) / 2 - 4,
              paddingRight: (width - currentMasonryWidth) / 2 - 4,
            }}
            width={width}
          />
        )}
      </AutoSizer>
    </Box>
  );
};

ProductList.propTypes = {
  isFavoriteList: PropTypes.bool,
  checkFavoriteFn: PropTypes.func.isRequired,
  products: PropTypes.arrayOf(PropTypes.object),
  emptyPlaceholder: PropTypes.node,
  onBottomReached: PropTypes.func,
};

ProductList.defaultProps = {
  isFavoriteList: false,
  products: [],
  emptyPlaceholder: null,
  onBottomReached: null,
};

export default trackWindowScroll(ProductList);
