import { Box } from '@material-ui/core';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import iconHanger from 'src/assets/images/ic_hanger.svg';
import EmptyPlaceholder from 'src/components/EmptyPlaceholder';
import LayoutTypeSwitch from 'src/components/LayoutTypeSwitch/LayoutTypeSwitch';
import Navbar from 'src/components/Navbar/Navbar';
import ProductList from 'src/components/ProductList/ProductList';
import { productsSelector } from 'src/store/products';
import { userSelector } from 'src/store/user';

const ShowroomTab = () => {
  const products = useSelector(productsSelector);
  const { favorites = {} } = useSelector(userSelector);

  return (
    <Box
      display="flex"
      flexDirection="column"
      height={1}
      overflow="scroll"
      width={1}
    >
      <Navbar title="Feed">
        <LayoutTypeSwitch />
      </Navbar>
      <ProductList
        checkFavoriteFn={({ asin }) => !!favorites[asin]}
        emptyPlaceholder={
          <EmptyPlaceholder icon={iconHanger} text="Your feed is empty." />
        }
        isFavoriteList={false}
        products={_.values(products)}
      />
    </Box>
  );
};

ShowroomTab.propTypes = {
  user: PropTypes.shape({
    favorites: PropTypes.object.isRequired,
    dislikes: PropTypes.object.isRequired,
  }).isRequired,
};

ShowroomTab.defaultProps = {};

export default ShowroomTab;
