import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import thunk from 'redux-thunk';

import alert from './alert';
import products from './products';
import tags from './tags';
import user from './user';

const rootReducer = combineReducers({
  user,
  products,
  tags,
  alert,
});

const middleware = [thunk];

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
