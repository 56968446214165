import Axios from 'axios';
import _ from 'lodash';

const FETCH_TAGS = 'tags/FETCH_TAGS';
const SET_TAGS = 'tags/SET_TAGS';

const initialState = {
  tags: [],
};

export const tagsSelector = (state) => state.tags.tags;

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_TAGS:
      return {
        ...state,
        tags: _(payload)
          .uniqBy((p) => _.trim(p.tag))
          .value(),
      };
    default:
      return state;
  }
};

export const getTags = () => (dispatch) => {
  dispatch({ type: FETCH_TAGS });
  Axios.get(process.env.REACT_APP_CURATION_TAGS_URL).then(
    ({ data: { data } }) => {
      dispatch({ type: SET_TAGS, payload: data });
    }
  );
};
