import {
  Box,
  Button,
  CircularProgress,
  Link,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';
import InstagramIcon from '@material-ui/icons/Instagram';
import firebase from 'firebase';
import { Formik } from 'formik';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { logo } from 'src/assets/images';
import { showAlert } from 'src/store/alert';
import * as yup from 'yup';

const httpsOrigin = window.origin.replace(/http.?:/, 'https:');
const redirectURL = encodeURIComponent(`${httpsOrigin}/`);
const appId = process.env.REACT_APP_INSTAGRAM_APP_ID;
const INSTAGRAM_AUTH_ENTRY = `https://api.instagram.com/oauth/authorize?app_id=${appId}&redirect_uri=${redirectURL}&scope=user_profile,user_media&response_type=code`;

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    '& > :not(:first-child)': {
      marginTop: theme.spacing(4),
    },
  },
  content: {
    maxWidth: 400,
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
}));

const initialValues = {
  email: '',
  password: '',
};

const validationSchema = yup.object({
  email: yup
    .string()
    .email('A valid email is required')
    .required('Email is required'),
  password: yup.string().required('Password is required'),
});

const Login = () => {
  const styles = useStyles();
  const dispatch = useDispatch();

  const handleSubmit = useCallback((values, { setSubmitting }) => {
    firebase
      .auth()
      .signInWithEmailAndPassword(values.email, values.password)
      .catch((err) => {
        dispatch(
          showAlert({
            message: err.message,
            severity: 'error',
          })
        );
      })
      .finally(() => setSubmitting(false));
  }, []);

  return (
    <Box
      alignItems="center"
      display="flex"
      height={1}
      justifyContent="center"
      width={1}
    >
      <Box
        alignItems="center"
        className={styles.content}
        display="flex"
        flexDirection="column"
        textAlign="center"
        width={1}
      >
        <Box mb={8}>
          <img alt="logo" src={logo} />
        </Box>
        <Box textAlign="center">
          <Typography variant="body1">
            Create a style profile to start your personal curation
          </Typography>
        </Box>
        <Box mt={4} width={1}>
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
          >
            {({
              values,
              errors,
              handleChange,
              handleSubmit,
              isSubmitting,
              isValid,
            }) => (
              <form className={styles.form} onSubmit={handleSubmit}>
                <TextField
                  aria-label="E-Mail"
                  autoComplete="username"
                  error={!!errors.email}
                  helperText={errors.email}
                  label="E-mail"
                  name="email"
                  onChange={handleChange}
                  value={values.email}
                  variant="outlined"
                />
                <TextField
                  aria-label="Password"
                  autoComplete="new-password"
                  error={!!errors.password}
                  helperText={errors.password}
                  label="Password"
                  name="password"
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                />
                <Button
                  color="primary"
                  disabled={!isValid || isSubmitting}
                  disableElevation
                  fullWidth
                  type="submit"
                  variant="contained"
                >
                  {isSubmitting ? <CircularProgress size={24} /> : 'Sign in'}
                </Button>
              </form>
            )}
          </Formik>
        </Box>
        <Box mt={4} width={1}>
          <Button
            color="primary"
            fullWidth
            onClick={() => {
              window.location.href = INSTAGRAM_AUTH_ENTRY;
            }}
            startIcon={<InstagramIcon />}
            variant="outlined"
          >
            Sign in with Instagram
          </Button>
        </Box>
        <Box mt={4} width={1}>
          <Link href="/forgot-password">
            <Typography variant="body2">Forgot password?</Typography>
          </Link>
        </Box>
        <Box mt={8} width={1}>
          <Typography>
            Don't have an account? <Link href="/sign-up">Sign Up</Link>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Login;
