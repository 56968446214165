import './styles.css';

import { IconButton, useTheme } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const StyleTag = (props) => {
  const { label, onRemoveClicked } = props;
  const theme = useTheme();
  return (
    <div className={cx('style-tag', { hidden: !label })}>
      <span>{label}</span>
      <IconButton onClick={onRemoveClicked} size="small">
        <CloseIcon style={{ color: theme.palette.primary.contrastText }} />
      </IconButton>
    </div>
  );
};

StyleTag.propTypes = {
  label: PropTypes.string,
  onRemoveClicked: PropTypes.func.isRequired,
};

StyleTag.defaultProps = {
  label: undefined,
};

export default StyleTag;
