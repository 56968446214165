import { Box, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

const EmptyPlaceholder = ({ icon, text }) => (
  <Box alignItems="center" display="flex" flex={1} flexDirection="column" p={8}>
    <img alt={text} src={icon} />
    <Box p={4}>
      <Typography variant="body1">{text}</Typography>
    </Box>
  </Box>
);

EmptyPlaceholder.propTypes = {
  icon: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

EmptyPlaceholder.defaultProps = {};

export default EmptyPlaceholder;
