import {
  Box,
  Button,
  CircularProgress,
  Link,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';
import firebase from 'firebase';
import { Formik } from 'formik';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { logo } from 'src/assets/images';
import { showAlert } from 'src/store/alert';
import * as yup from 'yup';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    '& > :not(:first-child)': {
      marginTop: theme.spacing(4),
    },
  },
  content: {
    maxWidth: 400,
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
}));

const initialValues = {
  email: '',
  password: '',
  firstName: '',
  lastName: '',
};

const validationSchema = yup.object({
  email: yup
    .string()
    .email('A valid email is required')
    .required('Email is required'),
  password: yup.string().required('Password is required'),
});

function Register() {
  const styles = useStyles();
  const dispatch = useDispatch();

  const handleSubmit = useCallback((values, { setSubmitting }) => {
    firebase
      .auth()
      .createUserWithEmailAndPassword(values.email, values.password)
      .then((result) => {
        if (result) {
          result.user.updateProfile({
            displayName: [values.firstName, values.lastName]
              .filter(Boolean)
              .join(' '),
          });
        }
      })
      .catch((err) => {
        dispatch(
          showAlert({
            message: err.message,
            severity: 'error',
          })
        );
      })
      .finally(() => {
        setSubmitting(false);
      });
  }, []);

  return (
    <Box
      alignItems="center"
      display="flex"
      height={1}
      justifyContent="center"
      width={1}
    >
      <Box
        alignItems="center"
        className={styles.content}
        display="flex"
        flexDirection="column"
        width={1}
      >
        <Box alignItems="center" display="flex" flex={1} mb={8}>
          <img alt="logo" src={logo} />
        </Box>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {({
            values,
            errors,
            handleChange,
            handleSubmit,
            isSubmitting,
            isValid,
          }) => (
            <form className={styles.form} onSubmit={handleSubmit}>
              <Box display="flex">
                <TextField
                  aria-label="First Name"
                  label="First Name"
                  name="firstName"
                  onChange={handleChange}
                  style={{ marginRight: 8, flex: 1 }}
                  value={values.firstName}
                  variant="outlined"
                />
                <TextField
                  aria-label="Last Name"
                  label="Last Name"
                  name="lastName"
                  onChange={handleChange}
                  style={{ flex: 1 }}
                  value={values.lastName}
                  variant="outlined"
                />
              </Box>
              <TextField
                aria-label="E-Mail"
                autoComplete="username"
                error={!!errors.email}
                helperText={errors.email}
                label="E-mail"
                name="email"
                onChange={handleChange}
                value={values.email}
                variant="outlined"
              />
              <TextField
                aria-label="Password"
                autoComplete="new-password"
                error={!!errors.password}
                helperText={errors.password}
                label="Password"
                name="password"
                onChange={handleChange}
                type="password"
                value={values.password}
                variant="outlined"
              />
              <Button
                color="primary"
                disabled={!isValid || isSubmitting}
                disableElevation
                fullWidth
                type="submit"
                variant="contained"
              >
                {isSubmitting ? <CircularProgress size={24} /> : 'Sign up'}
              </Button>
            </form>
          )}
        </Formik>
        <Box align="center" mt={4} width={1}>
          <Typography>
            Have an account? <Link href="/login">Sign in</Link>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default Register;
