import 'react-lazy-load-image-component/src/effects/blur.css';

import './App.css';

import { createMuiTheme } from '@material-ui/core';
import { blue } from '@material-ui/core/colors';
import grey from '@material-ui/core/colors/grey';
import { ThemeProvider } from '@material-ui/styles';
import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import AuthenticationGate from 'src/pages/AuthenticationGate';
import store from 'src/store';

const mainColor = grey[900];

const theme = createMuiTheme({
  typography: {
    fontFamily: ['Roboto Condensed', 'sans-serif'].join(','),
  },
  palette: {
    primary: {
      ...grey,
      main: mainColor,
    },
  },
  spacing: (factor) => `${0.25 * factor}rem`,
  overrides: {
    MuiLink: {
      root: {
        fontWeight: 'bold',
        color: blue[400],
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 0,
      },
    },
    MuiButton: {
      root: {
        borderRadius: 0,
        fontWeight: 'bold',
      },
      outlined: {
        borderWidth: 1,
        borderColor: mainColor,
      },
    },
  },
});

const App = () => (
  <div className="App">
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <Switch>
            <Route component={AuthenticationGate} path="/" />
          </Switch>
        </Provider>
      </ThemeProvider>
    </BrowserRouter>
  </div>
);

export default App;
