import { Box } from '@material-ui/core';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import iconHangerDoor from 'src/assets/images/ic_hanger_door.svg';
import EmptyPlaceholder from 'src/components/EmptyPlaceholder';
import LayoutTypeSwitch from 'src/components/LayoutTypeSwitch/LayoutTypeSwitch';
import Navbar from 'src/components/Navbar/Navbar';
import ProductList from 'src/components/ProductList/ProductList';

const MyClosetTab = (props) => {
  const { user } = props;

  return (
    <Box
      display="flex"
      flexDirection="column"
      height={1}
      overflow="scroll"
      width={1}
    >
      <Navbar title="My Closet">
        <LayoutTypeSwitch />
      </Navbar>
      <ProductList
        checkFavoriteFn={_.constant(true)}
        emptyPlaceholder={
          <EmptyPlaceholder
            icon={iconHangerDoor}
            text="Your closet is empty."
          />
        }
        isFavoriteList
        products={_.values(user.favorites)}
      />
    </Box>
  );
};

MyClosetTab.propTypes = {
  user: PropTypes.shape({
    favorites: PropTypes.object.isRequired,
  }).isRequired,
};

MyClosetTab.defaultProps = {};

export default MyClosetTab;
