import { Box, Typography, useTheme } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import React from 'react';

const Navbar = (props) => {
  const { title, children } = props;
  const theme = useTheme();
  return (
    <Box
      borderBottom={`1px solid ${theme.palette.primary.light}`}
      display="flex"
      height={64}
      px={theme.spacing(4)}
    >
      <Grid alignItems="center" container justify="space-between">
        <Grid item xs>
          <Typography variant="h6">{title.toUpperCase()}</Typography>
        </Grid>
        <Grid item>{children}</Grid>
      </Grid>
    </Box>
  );
};

Navbar.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
};

Navbar.defaultProps = {
  children: null,
};

export default Navbar;
