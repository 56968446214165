import firebase from 'firebase';
import { getCurrentUserUid } from 'src/helpers/user';

const db = firebase.firestore();

export const SET_USER = 'user/SET_USER';
const FETCH_USER = 'user/FETCH_USER';
const FETCH_USER_ERROR = 'user/FETCH_USER_ERROR';
const SET_STYLE_PROFILE = 'user/SET_STYLE_PROFILE';
const TOGGLE_COLUMNS = 'user/TOGGLE_COLUMNS';

const initialState = {
  dislikes: {},
  favorites: {},
  styleProfile: [],
  loaded: false,
  preferences: {
    columns: 1,
  },
};

export const userSelector = (state) => state.user;
export const columnPreferenceSelector = (state) =>
  state.user.preferences.columns;

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_USER: {
      return {
        ...state,
        ...payload,
        loaded: true,
      };
    }
    case TOGGLE_COLUMNS: {
      return {
        ...state,
        preferences: {
          ...state.preferences,
          columns: payload.columns,
        },
      };
    }
    default:
      return state;
  }
};

export const getUser = () => (dispatch) => {
  dispatch({ type: FETCH_USER });
  db.collection('users')
    .doc(getCurrentUserUid())
    .onSnapshot(
      (snap) => dispatch({ type: SET_USER, payload: snap.data() }),
      (error) => dispatch({ type: FETCH_USER_ERROR, payload: error })
    );
};

export const setStyleProfile = (styleProfile) => (dispatch) => {
  dispatch({ type: SET_STYLE_PROFILE, payload: styleProfile });
  db.collection('users').doc(getCurrentUserUid()).set(
    {
      styleProfile,
    },
    { merge: true }
  );
};

export const setSingleColumnLayout = () => (dispatch) => {
  const preferences = { columns: 1 };
  db.collection('users').doc(getCurrentUserUid()).set(
    {
      preferences,
    },
    { merge: true }
  );
  dispatch({
    type: TOGGLE_COLUMNS,
    payload: preferences,
  });
};

export const setTwoColumnLayout = () => (dispatch) => {
  const preferences = { columns: 2 };
  db.collection('users').doc(getCurrentUserUid()).set(
    {
      preferences,
    },
    { merge: true }
  );
  dispatch({
    type: TOGGLE_COLUMNS,
    payload: preferences,
  });
};
