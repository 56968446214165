import { IconButton } from '@material-ui/core';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import buttonFeedBig from 'src/assets/images/ic_feed_big_preview.svg';
import buttonFeedColumns from 'src/assets/images/ic_feed_columns.svg';
import {
  columnPreferenceSelector,
  setSingleColumnLayout,
  setTwoColumnLayout,
} from 'src/store/user';

const LayoutTypeSwitch = () => {
  const dispatch = useDispatch();
  const columns = useSelector(columnPreferenceSelector);

  return (
    <IconButton
      onClick={() =>
        columns === 1
          ? dispatch(setTwoColumnLayout())
          : dispatch(setSingleColumnLayout())
      }
    >
      <img
        alt="Toggle view"
        height={20}
        src={columns === 1 ? buttonFeedColumns : buttonFeedBig}
        width={20}
      />
    </IconButton>
  );
};

LayoutTypeSwitch.propTypes = {};

LayoutTypeSwitch.defaultProps = {};

export default LayoutTypeSwitch;
