import './styles.css';

import { Box, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import logo from 'src/assets/images/otaku-logo.png';

export default function FullPageLoader({ message }) {
  return (
    <Box
      alignItems="center"
      display="flex"
      flexDirection="column"
      height={1}
      justifyContent="center"
      width={1}
    >
      <img alt="logo" className="pulse" src={logo} />
      <br />
      <Typography color="textSecondary" variant="body2">
        {message}…
      </Typography>
    </Box>
  );
}

FullPageLoader.propTypes = {
  message: PropTypes.string.isRequired,
};
