import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';
import firebase from 'firebase';
import { Formik } from 'formik';
import qs from 'qs';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { logo } from 'src/assets/images';
import { showAlert } from 'src/store/alert';
import * as yup from 'yup';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    '& > :not(:first-child)': {
      marginTop: theme.spacing(4),
    },
  },
  content: {
    maxWidth: 400,
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
}));

const initialValues = {
  password: '',
  confirmPassword: '',
};

const validationSchema = yup.object({
  password: yup.string().required('Password is required'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password')], "Passwords don't match"),
});

function ResetPassword() {
  const styles = useStyles();
  const dispatch = useDispatch();
  const query = qs.parse(useLocation().search, { ignoreQueryPrefix: true });
  const history = useHistory();

  const handleSubmit = useCallback(
    (values, { setSubmitting }) => {
      firebase
        .auth()
        .confirmPasswordReset(query.oobCode, values.password)
        .then(() => {
          history.replace('/');
          dispatch(
            showAlert({
              message: 'Your password was successfully changed.',
              severity: 'success',
            })
          );
        })
        .catch((err) => {
          dispatch(
            showAlert({
              message: err.message,
              severity: 'error',
            })
          );
        })
        .finally(() => setSubmitting(false));
    },
    [query, history]
  );

  return (
    <Box
      alignItems="center"
      display="flex"
      height={1}
      justifyContent="center"
      width={1}
    >
      <Box
        alignItems="center"
        className={styles.content}
        display="flex"
        flexDirection="column"
        textAlign="center"
        width={1}
      >
        <Box mb={8}>
          <img alt="logo" src={logo} />
        </Box>
        <Box>
          <Typography>Enter your new password:</Typography>
        </Box>
        <Box mt={4} width={1}>
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
          >
            {({
              values,
              errors,
              handleChange,
              handleSubmit,
              isSubmitting,
              isValid,
            }) => (
              <form className={styles.form} onSubmit={handleSubmit}>
                <TextField
                  aria-label="Password"
                  autoComplete="new-password"
                  error={!!errors.password}
                  helperText={errors.password}
                  label="Password"
                  name="password"
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                />
                <TextField
                  aria-label="Confirm Password"
                  autoComplete="new-password"
                  error={!!errors.confirmPassword}
                  helperText={errors.confirmPassword}
                  label="Confirm Password"
                  name="confirmPassword"
                  onChange={handleChange}
                  type="password"
                  value={values.confirmPassword}
                  variant="outlined"
                />
                <Button
                  color="primary"
                  disabled={!isValid || isSubmitting}
                  disableElevation
                  fullWidth
                  type="submit"
                  variant="contained"
                >
                  {isSubmitting ? (
                    <CircularProgress size={24} />
                  ) : (
                    'Reset Password'
                  )}
                </Button>
              </form>
            )}
          </Formik>
        </Box>
      </Box>
    </Box>
  );
}

export default ResetPassword;
