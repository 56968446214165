import { Box, Button, Grow, Typography, useTheme } from '@material-ui/core';
import ArrowForward from '@material-ui/icons/ArrowForward';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CircleTagPicker from 'src/components/BubbleTagPicker/CircleTagPicker';
import StyleTag from 'src/components/StyleTag';
import { getFeed, setProducts } from 'src/store/products';
import { tagsSelector } from 'src/store/tags';
import { setStyleProfile } from 'src/store/user';

/**
 * StyleProfile
 * @param {Object} props
 * @param {User} props.user
 */
const StyleProfile = (props) => {
  const { user, onDone } = props;
  const tags = useSelector(tagsSelector);
  const dispatch = useDispatch();
  const theme = useTheme();
  const [choices, setChoices] = useState(
    _.filter(tags, ({ tag }) => _.includes(user.styleProfile, tag))
  );
  const [isSubmitting, setIsSubmitting] = useState(false);

  const submit = () => {
    setIsSubmitting(true);
    dispatch(getFeed(choices))
      .then((data) => dispatch(setProducts(data)))
      .finally(() => setIsSubmitting(false))
      .then(onDone)
      .then(() => dispatch(setStyleProfile(_.map(choices, 'tag'))));
  };

  const onTagSelected = (tag) =>
    setChoices((picked) => _.slice(_.uniqBy(picked.concat(tag), 'tag'), -3));

  const onTagUnselected = (tag) =>
    setChoices((picked) =>
      _.reject(picked, (choice) => choice.tag === tag.tag)
    );

  return (
    <Grow in>
      <Box display="flex" flex={1} flexDirection="column" textAlign="center">
        <Box display="flex" flexDirection="column" pt={4} px={4}>
          <Typography autoCapitalize="all" gutterBottom variant="h4">
            FIND THINGS THAT ARE…
          </Typography>
          <Typography gutterBottom variant="body1">
            Select three words that describe what you are looking for…
          </Typography>
        </Box>
        <Box
          bgcolor="white"
          height="auto"
          overflow="scroll"
          position="sticky"
          px={4}
          py={2}
          top={0}
          whiteSpace="nowrap"
          zIndex={1}
        >
          {_.map(new Array(3).fill(1), (v, index) => (
            <StyleTag
              label={_.get(choices, [index, 'tag'])}
              onRemoveClicked={() => onTagUnselected(choices[index])}
            />
          ))}
        </Box>
        <Box height="2rem" />
        <Box display="flex" flex={1}>
          <CircleTagPicker
            circles={tags}
            onTagSelected={onTagSelected}
            onTagUnselected={onTagUnselected}
            selectedTags={choices}
          />
        </Box>
        <Box height="6rem" />
        <Box display="flex">
          <Button
            disabled={_.size(choices) !== 3 || isSubmitting}
            endIcon={<ArrowForward />}
            fullWidth
            onClick={submit}
            size="large"
            style={{
              position: 'fixed',
              height: theme.spacing(16),
              bottom: 0,
              backgroundColor: isSubmitting
                ? theme.palette.primary.light
                : theme.palette.primary.main,
              color: theme.palette.primary.contrastText,
            }}
            variant="contained"
          >
            Search
          </Button>
        </Box>
      </Box>
    </Grow>
  );
};

StyleProfile.propTypes = {
  user: PropTypes.shape({
    username: PropTypes.string,
    styleProfile: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  onDone: PropTypes.func.isRequired,
};

StyleProfile.defaultProps = {};

export default StyleProfile;
