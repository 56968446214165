import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import firebase from 'firebase';
import qs from 'qs';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router';
import rp from 'request-promise';
import FullPageLoader from 'src/components/FullPageLoader';
import { guardedRoute } from 'src/navigation';
import Dashboard from 'src/pages/Dashboard';
import Login from 'src/pages/Login';
import Register from 'src/pages/Register/Register';
import { alertSelector, hideAlert } from 'src/store/alert';

import AuthActions from '../AuthActions/AuthActions';
import ForgotPassword from '../ForgotPassword/ForgotPassword';

const LOGIN_URL = 'https://us-central1-style-otaku.cloudfunctions.net/login';

const login = (code) =>
  rp
    .post(LOGIN_URL, {
      body: { code },
      json: true,
    })
    .then(({ token: accessToken }) =>
      firebase.auth().signInWithCustomToken(accessToken)
    );

const AuthorizedRoutes = () => {
  const { search } = useLocation();
  const { code } = qs.parse(search, { ignoreQueryPrefix: true });
  const [authenticated, setAuthenticated] = useState(null);
  const [authenticating, setAuthenticating] = useState(false);
  const alert = useSelector(alertSelector);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(
    () =>
      firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          if (process.env.NODE_ENV === 'development') {
            window.firebase = firebase;
          }
          setAuthenticated(true);
          history.replace(guardedRoute(history.location.pathname));
        } else {
          setAuthenticated(false);
        }
      }),
    [history]
  );

  useEffect(() => {
    if (code) {
      setAuthenticating(true);
      login(code).finally(() => setAuthenticating(false));
    }
  }, [code]);

  if (authenticating) {
    return <FullPageLoader message="Signing in" />;
  }

  if (authenticated === null) {
    return null;
  }

  return (
    <>
      <Switch>
        <Route component={Login} exact path="/login" />
        <Route component={Register} exact path="/sign-up" />
        <Route component={ForgotPassword} exact path="/forgot-password" />
        <Route component={AuthActions} exact path="/auth" />
        {authenticated && <Route component={Dashboard} path="/" />}
        <Redirect to="/login" />
      </Switch>
      <Snackbar
        autoHideDuration={5000}
        onClose={() => dispatch(hideAlert())}
        open={alert.visible}
      >
        <Alert severity={alert.severity}>{alert.message}</Alert>
      </Snackbar>
    </>
  );
};

AuthorizedRoutes.propTypes = {};

AuthorizedRoutes.defaultProps = {};

export default AuthorizedRoutes;
