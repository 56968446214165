import {
  Avatar,
  Box,
  List,
  ListItem,
  ListItemText,
  Typography,
  makeStyles,
  useTheme,
} from '@material-ui/core';
import firebase from 'firebase';
import PropTypes from 'prop-types';
import React from 'react';
import Navbar from 'src/components/Navbar/Navbar';
import { logOut } from 'src/store/auth';

const useStyles = makeStyles((theme) => ({
  large: {
    width: theme.spacing(48),
    height: theme.spacing(48),
    marginBottom: theme.spacing(4),
    borderColor: theme.palette.primary.main,
    borderWidth: 1,
    borderStyle: 'solid',
  },
  usernameText: {
    fontWeight: 'bold',
  },
  logoutText: {
    color: 'red',
  },
}));

const MeTab = (props) => {
  const { user } = props;
  const theme = useTheme();
  const classes = useStyles();
  return (
    <Box display="flex" flexDirection="column" height={1} width={1}>
      <Navbar title="Profile" />
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        py={theme.spacing(4)}
        width={1}
      >
        <Avatar
          alt={user.username}
          className={classes.large}
          src={user.profile_image}
        >
          {user.username}
        </Avatar>
        <Typography className={classes.usernameText} variant="body1">
          {[
            user.username && `@${user.username}`,
            firebase.auth().currentUser.displayName,
            firebase.auth().currentUser.email,
          ].find(Boolean)}
        </Typography>
      </Box>
      <List aria-label="main logout" component="nav">
        <ListItem button onClick={logOut}>
          <ListItemText className={classes.logoutText} primary="Log out" />
        </ListItem>
      </List>
    </Box>
  );
};

MeTab.propTypes = {
  user: PropTypes.shape({
    username: PropTypes.string,
    profile_image: PropTypes.string,
  }).isRequired,
};

MeTab.defaultProps = {};

export default MeTab;
