import './index.css';

import firebase from 'firebase';
import React from 'react';
import ReactDOM from 'react-dom';
import { firebaseConfig } from 'src/config';

firebase.initializeApp(firebaseConfig);

const App = require('src/pages/App').default;

ReactDOM.render(<App />, document.getElementById('root'));
