import { Box, Button, IconButton } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import PropTypes from 'prop-types';
import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useDispatch } from 'react-redux';
import { CARD_HEIGHT, CARD_WIDTH } from 'src/constants';
import { AnimatedClasses, animate } from 'src/helpers/animateIcon';
import { addFavorite, hideProduct, removeFavorite } from 'src/store/products';

const useStyles = makeStyles((theme) => ({
  noBorder: {
    borderRadius: 0,
    borderColor: 'transparent',
  },
  cardMedia: {
    height: CARD_HEIGHT,
    objectFit: 'cover',
    pointerEvents: 'none',
  },
  cardContent: {
    overflow: 'hidden',
  },
  titleText: {
    WebkitLineClamp: 2,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
  },
  actionButtonsContainer: {
    position: 'absolute',
    top: 0,
    right: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
  },
  actionButton: {
    marginTop: theme.spacing(1),
    backgroundColor: `${theme.palette.primary.main}80`,
    '&:hover': {
      backgroundColor: `${theme.palette.primary.main}80`,
    },
  },
  actionIcon: {
    color: theme.palette.primary.contrastText,
  },
}));

const ProductCard = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    product,
    width,
    style,
    scrollPosition,
    showAlways,
    isFavorite,
  } = props;

  let actionIcon = null;
  if (showAlways) {
    actionIcon = isFavorite ? (
      <FavoriteIcon className={classes.actionIcon} style={{ color: 'red' }} />
    ) : (
      <FavoriteIcon className={classes.actionIcon} />
    );
  } else {
    actionIcon = <CloseIcon className={classes.actionIcon} />;
  }

  const onActionButtonPressed = () => {
    const favorited = !isFavorite;
    dispatch(favorited ? addFavorite(product) : removeFavorite(product));
    if (favorited) {
      animate(AnimatedClasses.bottomNavFavoriteIcon);
    }
  };

  return (
    <Card
      className={classes.noBorder}
      style={{ ...style, width }}
      variant="outlined"
    >
      <LazyLoadImage
        alt={product.title}
        className={classes.cardMedia}
        effect="blur"
        height={CARD_HEIGHT}
        scrollPosition={scrollPosition}
        src={product.image_url}
        width={width}
      />
      <div className={classes.actionButtonsContainer}>
        <IconButton
          className={classes.actionButton}
          onClick={onActionButtonPressed}
        >
          {actionIcon}
        </IconButton>
        {showAlways && (
          <IconButton
            className={classes.actionButton}
            onClick={() => dispatch(hideProduct(product))}
          >
            <ThumbDownIcon className={classes.actionIcon} />
          </IconButton>
        )}
      </div>
      <CardContent className={classes.cardContent}>
        <Typography className={classes.titleText} variant="body1">
          {product.title}
        </Typography>
        <Box
          alignItems="center"
          display="flex"
          justifyContent="space-between"
          pt={2}
        >
          <Typography style={{ fontWeight: 'bold' }} variant="h6">
            {`$${product.price}`}
          </Typography>
          <Button
            onClick={() => window.open(product.shop_url)}
            variant="outlined"
          >
            BUY
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};

ProductCard.propTypes = {
  showAlways: PropTypes.bool.isRequired,
  product: PropTypes.object.isRequired,
  style: PropTypes.object.isRequired,
  width: PropTypes.number,
  scrollPosition: PropTypes.oneOfType([PropTypes.number, null]),
  isFavorite: PropTypes.bool,
};

ProductCard.defaultProps = {
  width: CARD_WIDTH,
  isFavorite: false,
  scrollPosition: null,
};

export default ProductCard;
