import firebase from 'firebase';
import _ from 'lodash';

const getUid = (firebaseUid) => firebaseUid.replace('instagram:', '');

/**
 * @returns {string}
 */
export const getCurrentUserUid = () =>
  getUid(_.get(firebase.auth(), 'currentUser.uid', ''));

export const getCurrentUserShowroom = () => `showroom_${getCurrentUserUid()}`;

export const getUserVisibleProducts = (products, user) =>
  _.reject(products, (p) => _.includes(user.hidden, p.id));
