import qs from 'qs';
import React from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router';

import ResetPassword from '../ResetPassword/ResetPassword';

function AuthActions() {
  const query = qs.parse(useLocation().search, { ignoreQueryPrefix: true });

  return (
    <Switch>
      {query.mode === 'resetPassword' && <Route component={ResetPassword} />}
      <Redirect to="/" />
    </Switch>
  );
}

export default AuthActions;
