import {
  Box,
  Button,
  CircularProgress,
  Link,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';
import firebase from 'firebase';
import { Formik } from 'formik';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { logo } from 'src/assets/images';
import { showAlert } from 'src/store/alert';
import * as yup from 'yup';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    '& > :not(:first-child)': {
      marginTop: theme.spacing(4),
    },
  },
  content: {
    maxWidth: 400,
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
}));

const initialValues = {
  email: '',
};

const validationSchema = yup.object({
  email: yup
    .string()
    .email('A valid email is required')
    .required('Email is required'),
});

function ForgotPassword() {
  const styles = useStyles();
  const dispatch = useDispatch();

  const handleSubmit = useCallback((values, { setSubmitting }) => {
    firebase
      .auth()
      .sendPasswordResetEmail(values.email)
      .then(() => {
        dispatch(
          showAlert({
            message:
              'Thanks! Please check your email for a link to reset your password.',
            severity: 'success',
          })
        );
      })
      .catch((err) => {
        dispatch(
          showAlert({
            message: err.message,
            severity: 'error',
          })
        );
      })
      .finally(() => setSubmitting(false));
  }, []);

  return (
    <Box
      alignItems="center"
      display="flex"
      height={1}
      justifyContent="center"
      width={1}
    >
      <Box
        alignItems="center"
        className={styles.content}
        display="flex"
        flexDirection="column"
        textAlign="center"
        width={1}
      >
        <Box mb={8}>
          <img alt="logo" src={logo} />
        </Box>
        <Box>
          <Typography>
            Enter your email and we'll send you instructions on how to get back
            into your account.
          </Typography>
        </Box>
        <Box mt={4} width={1}>
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
          >
            {({
              values,
              errors,
              handleChange,
              handleSubmit,
              isSubmitting,
              isValid,
            }) => (
              <form className={styles.form} onSubmit={handleSubmit}>
                <TextField
                  aria-label="E-Mail"
                  autoComplete="username"
                  error={!!errors.email}
                  helperText={errors.email}
                  label="E-mail"
                  name="email"
                  onChange={handleChange}
                  value={values.email}
                  variant="outlined"
                />
                <Button
                  color="primary"
                  disabled={!isValid || isSubmitting}
                  disableElevation
                  fullWidth
                  type="submit"
                  variant="contained"
                >
                  {isSubmitting ? (
                    <CircularProgress size={24} />
                  ) : (
                    'Request Password Reset'
                  )}
                </Button>
              </form>
            )}
          </Formik>
        </Box>
        <Box mt={8}>
          <Link href="/login">Back to Login</Link>
        </Box>
      </Box>
    </Box>
  );
}

export default ForgotPassword;
