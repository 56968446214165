import './styles.css';

import {
  BottomNavigation,
  BottomNavigationAction,
  Box,
  useTheme,
} from '@material-ui/core';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteIconOutlined from '@material-ui/icons/FavoriteBorder';
import PersonIcon from '@material-ui/icons/Person';
import PersonIconOutlined from '@material-ui/icons/PersonOutlined';
import WidgetsIcon from '@material-ui/icons/Widgets';
import WidgetsIconOutlined from '@material-ui/icons/WidgetsOutlined';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, useHistory, useLocation } from 'react-router';
import FullPageLoader from 'src/components/FullPageLoader/FullPageLoader';
import { AnimatedClasses } from 'src/helpers/animateIcon';
import { routes } from 'src/navigation';
import MeTab from 'src/pages/MeTab';
import MyClosetTab from 'src/pages/MyClosetTab';
import ShowroomTab from 'src/pages/ShowroomTab';
import StyleProfile from 'src/pages/StyleProfile';
import { getTags, tagsSelector } from 'src/store/tags';
import { getUser, userSelector } from 'src/store/user';

const TabLinks = ['/feed', '/closet', '/me'];

const Dashboard = () => {
  const history = useHistory();
  const user = useSelector(userSelector);
  const tags = useSelector(tagsSelector);
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const theme = useTheme();
  const [hasStyleProfile, setHasStyleProfile] = useState(false);
  const [tab, setTab] = useState(Math.max(0, TabLinks.indexOf(pathname)));

  useEffect(() => {
    if (!routes.includes(pathname)) {
      history.replace(routes[0]);
    }
  });

  useEffect(() => {
    history.replace(TabLinks[tab]);
  }, [tab, history]);

  useEffect(() => {
    dispatch(getUser());
    dispatch(getTags());
  }, [dispatch]);

  if (!user.loaded) {
    return <FullPageLoader message="Loading your profile" />;
  }

  if (!tags.length) {
    return <FullPageLoader message="Getting style types" />;
  }

  if (!hasStyleProfile) {
    return (
      <StyleProfile
        onDone={() => {
          setHasStyleProfile(true);
        }}
        user={user}
      />
    );
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      height={1}
      overflow="hidden"
      position="fixed"
      width={1}
    >
      <Box display="flex" flex={1}>
        <Route path="/feed">
          <ShowroomTab user={user} />
        </Route>
        <Route path="/closet">
          <MyClosetTab user={user} />
        </Route>
        <Route path="/me">
          <MeTab user={user} />
        </Route>
      </Box>
      <Box bgcolor={theme.palette.primary.light} height="1px" width={1} />
      <BottomNavigation
        onChange={(event, newValue) => {
          setTab(newValue);
        }}
        value={tab}
      >
        <BottomNavigationAction
          icon={tab === 0 ? <WidgetsIcon /> : <WidgetsIconOutlined />}
        />
        <BottomNavigationAction
          icon={
            tab === 1 ? (
              <FavoriteIcon className={AnimatedClasses.bottomNavFavoriteIcon} />
            ) : (
              <FavoriteIconOutlined
                className={AnimatedClasses.bottomNavFavoriteIcon}
              />
            )
          }
        />
        <BottomNavigationAction
          icon={tab === 2 ? <PersonIcon /> : <PersonIconOutlined />}
        />
      </BottomNavigation>
    </Box>
  );
};

export default Dashboard;
